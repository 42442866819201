import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowContext } from "../contexts/SideWindowContext";
import { SideWindowType } from "../layouts/OverviewSplitManager";
import FilesHierarchy from "../components/content/FilesHierarchy";
import ProcessesHierarchy from "../components/content/ProcessesHierarchy";
import SensorsHierarchy from "../components/content/SensorsHierarchy";
import RoomInfo from "../components/tables/RoomInfo";
import EstateService from "../services/EstateService";
import { IRoomDetailed } from "../types/estateTypes";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import { prepareWFSLink } from "../components/maps/MapFunctions";
import ItemService from "../../items/services/ItemService";
import ItemsHierarchy from "../../items/components/content/ItemsHierarchy";
import FileService from "../../files/services/FileService";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import PrintableWrapper from "../../common/components/PrintableWrapper";

export default function RoomPage(){
    const { changeSideWindow, openSideWindow, openHierarchy, localMap, setSwitcherOptions, matterport, closeSideWindow } = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IRoomDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
    }, [id])
    useEffect(() => {
        if(data !== undefined){
            setSwitcherOptions((data.hasDrawing || data.hasMDTile), data.hasMatterport)
            if((data.hasDrawing || data.hasMDTile)){
                openSideWindow();
                changeSideWindow(SideWindowType.LocalMap);
            }
            else if(data.hasMatterport){
                openSideWindow();
                changeSideWindow(SideWindowType.Matterport);
            }
            else
                closeSideWindow();
        }
        
    }, [data])
    useEffect(() => {
        if(data !== undefined){
            setMapVectorData();
        }
        
    }, [data, localMap])
    useEffect(() => {
        if(data !== undefined){
            setMatterportData();
        }
        
    }, [data, matterport])
    function loadData(){
        EstateService.getHierarchyData("Rooms/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(data && localMap){
            localMap?.setActiveConnectors([{id: data.id, type: SkobaEntityType.Room}])
            localMap?.setWfsLayers(prepareWFSLink(data.id, SkobaEntityType.Room));
        }       
    }
    function setMatterportData(): void{
        if(data && matterport){          
            matterport?.loadData(data.id, SkobaEntityType.Room)
        }       
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew 
                title={data!.name}
                id={data.id} 
                type={SkobaEntityType.Room} 
                tabTitles={["Informace", "Čidla", "Prvky", "Revize","Činnosti", "Soubory"]} >
                    <PrintableWrapper>
                        <RoomInfo data={data} onMount={() => {
                            if(data?.hasDrawing  || data?.hasMDTile){
                                openSideWindow();
                                changeSideWindow(SideWindowType.LocalMap);
                                setMapVectorData();
                            }
                            else if(data?.hasMatterport){
                                openSideWindow();
                                changeSideWindow(SideWindowType.Matterport);
                            }
                            else
                                closeSideWindow();
                        }}
                        onRefresh={() => {}}
                        refreshProps={[data, localMap]}/>
                    </PrintableWrapper>
                    <SensorsHierarchy 
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Room, data.id, SkobaEntityType.Sensor)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Room}}
                        getData={async (category: CategoryType, subCategory: CategoryType) => ItemService.getAllOfParent(SkobaEntityType.Room, data.id, category, subCategory)}/>
                    <ProcessesHierarchy 
                        category={CategoryType.Revision}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Room}} />
                    <ProcessesHierarchy 
                        category={CategoryType.Activity}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Room}} />
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Room, data.id)}
                        parent={{name: data.name, id: data.id, type: 3}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }