import { getConnectorParentRoute, getParentName, getParentRoute } from "../../../common/Functions";
import { useNavigate } from "react-router-dom";
import PropertiesInfoTable, { IPropertyInfoRowDefinition } from "../../../common/components/tables/PropertiesInfoTable";
import { IItem, IItemDetailed, IItemProperty, IPropertyValue } from "../../types/itemTypes";
import FormModal from "../../../categories/components/modals/FormModal";
import DeleteModal from "../../../categories/components/modals/DeleteModal";
import ItemForm from "../forms/ItemForm";
import ItemService from "../../services/ItemService";
import { useEffect } from "react";

export interface IItemInfoInfoProps{
    data : IItemDetailed
    values : IPropertyValue[]
    properties : IItemProperty[]
    onMount? : () => void;
    onRefresh? : () => void;
    refreshProps?: any[];
}

export default function ItemInfo(props : IItemInfoInfoProps){    
    const navigate = useNavigate();
    useEffect(() => {
        if(props.onMount){
            props.onMount();
        }
    }, [])
    useEffect(() => {
        if(props.onRefresh){
            props.onRefresh();
        }
    }, props.refreshProps)
    function getRowDefinition(){
        const rowDefinition = [{
            name : "Název", 
            property : "name",            
        },{
            name : "Specifikace", 
            property: "definitionName"
        },,{
            name : "Typ", 
            property: "definitionTypeName"
        },{
            name : "Podtyp", 
            property: "definitionTypeParentTypeName"
        },{
            name : "Položka", 
            property: "locationName",
            onClick: (object) => navigate(getConnectorParentRoute(object))
        }] as IPropertyInfoRowDefinition[];
        props.properties.forEach((property) => {
            rowDefinition.push({
                name : property.name,
                property: property.id,
                getValue: (object) => {
                    const value = props.values.find((value) => value.propertyId === property.id);
                    return value ? value.value : "";
                }
            })
        })
        return rowDefinition;
    }
    return <PropertiesInfoTable
        data={props.data}
        rowDefinition={getRowDefinition()}
        buttons={(object) => [
            <FormModal  
            key={1}                        
            title="Upravit"    
            buttonElement={<span>Upravit</span>}
            form={<ItemForm buttonText="Uložit změny" model={props.data}  
                    onSubmit={ItemService.updateItem}
                />}
            />,
            <DeleteModal key={2} buttonText="Smazat" itemName={props.data.name} 
                onConfirm={() => ItemService.deleteItem(props.data).then(() => {navigate("/estate/items")})}/>
        ]}
    />
}