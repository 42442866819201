import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../services/EstateService";
import { SideWindowType } from "../../layouts/OverviewSplitManager";
import { SideWindowContext } from "../../contexts/SideWindowContext";
import FormModal from "../../../categories/components/modals/FormModal";
import { SkobaEntityType, ISelectBetter, CategoryType } from "../../../common/types/skobaTypes";
import SingleTextFilter from "../../../common/components/filters/SingleTextFilter";
import { Grid, InputLabel, MenuItem, RadioGroup, FormControlLabel, Radio, FormControl, Select, FormLabel, Box, Stack, Typography } from "@mui/material";
import { IItemCategory } from "../../../items/types/itemTypes";
import UnclosedProcessesTable from "../../../processes/components/tables/UnclosedProcessesTable";
import ClosedProcessesTable from "../../../processes/components/tables/ClosedProcessesTable";
import UpcomingProcessesTable from "../../../processes/components/tables/UpcomingProcessesTable";
import ProcessService from "../../../processes/services/ProcessService";
import ProcessForm from "../../../processes/components/forms/ProcessForm";
import { RefreshDataContext } from "../../../common/contexts/RefreshDataContext";
import LoadingWrapper from "../../../common/components/LoadingWrapper";
import TabLayout from "../../../common/layouts/TabLayout";
import CategoryService from "../../../categories/services/CategoryService";
import ProcessesSummary from "../../../processes/components/tables/ProcessesSummary";
import PrintableWrapper from "../../../common/components/PrintableWrapper";
import { LocalizationProvider, csCZ, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";


export interface IProcessesHierarchyProps{
    category : CategoryType;
    select?: ISelectBetter;
}

export default function ProcessesHierarchy(props: IProcessesHierarchyProps){    
    const { changeSideWindow, openSideWindow, openHierarchy, chartObj, controlMap, localMap } = useContext(SideWindowContext);
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<number>(0);
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().add(7, 'day'));
    const [categories, setCategories] = useState<IItemCategory[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [error, setError] = useState<any>(null);
    const [errorFilters, setErrorFilters] = useState<any>(null);
    const today = new Date(Date.now())

    useEffect(() => {
        loadData();
        openSideWindow();
        changeSideWindow(SideWindowType.ChartManager)
    }, []) 
    useEffect(() => {
        chartObj?.changeData(data, categories, 1, props.category);
    }, [chartObj, data, categories]) 

    useEffect(() => {
        getProcesses()
    }, [startDate, endDate])
    function loadData(){
        const categoriesPromise = CategoryService.getCategoriesByType(props.category);
        const processesPromise = props.select === undefined ? ProcessService.getToDate(startDate!.toISOString(), endDate!.toISOString(), props.category) : ProcessService.getToDateOfParent(startDate!.toISOString(), endDate!.toISOString(), props.select.type, props.select.id, props.category);
        Promise.all([categoriesPromise, processesPromise])
        .then(
            ([data1, data2]) => {
                setLoaded(true);
                setLoadedData(true);
                setCategories(data1);
                setData(data2);
            },
            (error) => {
                setLoaded(true);
                setLoadedData(true);
                setError(error);
                setErrorFilters(error);
            }
        )
    }
    function getProcesses(){
        setLoadedData(false);
        const promise = props.select === undefined ? ProcessService.getToDate(startDate!.toISOString(), endDate!.toISOString(), props.category) : ProcessService.getToDateOfParent(startDate!.toISOString(), endDate!.toISOString(), props.select.type, props.select.id, props.category);
        promise.then(
            (result) => {
                setLoadedData(true);
                setData(result);
            },
            (error) => {
                setLoadedData(true);
            setError(error);
            }
        );
    }
    function onFilterChange(data: any[]){
        setFilteredData(data);
    }
    function getFilters() {
        return <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="main-item-category-filter">Kategorie</InputLabel>
                    <Select 
                        variant="filled"   
                        fullWidth
                        labelId="main-item-category-filter"
                        label="Kategorie" 
                        value={categoryFilter.toString()}
                        defaultValue={""}
                        onChange={(event) => {
                            setCategoryFilter(parseInt(event.target.value));
                        }}
                    >
                        <MenuItem value={"0"}>Všechny kategorie</MenuItem>
                        {categories.map((category, index) => <MenuItem key={index} value={category.id.toString()}>{category.name}</MenuItem>)}
                    </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}
                    adapterLocale='cs'
                    localeText={
                        csCZ.components.MuiLocalizationProvider.defaultProps.localeText
                    }>
                        <DatePicker 
                            value={dayjs(startDate)} 
                            label="Začátek" 
                            onChange={setStartDate}
                            slotProps={{
                                textField: {
                                    variant: "outlined"
                                }
                            }}                            
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>            
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}
                    adapterLocale='cs'
                    localeText={
                        csCZ.components.MuiLocalizationProvider.defaultProps.localeText
                    }>
                        <DatePicker 
                            value={dayjs(endDate)} 
                            label="Konec" 
                            onChange={setEndDate}
                            slotProps={{
                                textField: {
                                    variant: "outlined"
                                }
                            }}
                            minDate={startDate ? startDate : dayjs()}                            
                            format="DD/MM/YYYY"                            
                        />
                    </LocalizationProvider>            
                </FormControl>
            </Grid>
        </Grid>
    }
    function getTabName(){
        if(props.category === CategoryType.Revision){
            return "revize"
        }
        if(props.category === CategoryType.Activity)
            return "činnosti"
        // return "události"
    }
    function getModalName(){
        if(props.category === CategoryType.Revision){
            return "revizi"
        }
        if(props.category === CategoryType.Activity)
            return "činnost"
        // return "událost"
    }
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={error} loading={!loaded}>
            <Box sx={{p:0, height:"100%", '& > *': { mb: 2 }}}>
                <Grid container spacing={2} rowSpacing={2}>
                        <Grid item xs={12}>                    
                            <SingleTextFilter 
                                data={data} 
                                onChange={onFilterChange} 
                                filterProperty="name"
                                labelText={"Hledat dle názvu " + getTabName()}/>
                        </Grid>
                        <Grid item xs={12}>
                            {getFilters()}                    
                        </Grid>
                </Grid>
                <TabLayout queryPrefix="ph" titles={[
                    "Planované " + getTabName(),
                    filteredData.filter((item) => new Date(item.startDate) <= today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter)).length === 0 ?
                    "Neuzavřené " + getTabName()
                    :
                    <Typography sx={{color:"red", fontWeight:"bold"}}>{"Neuzavřené " + getTabName()}
                    </Typography>
                    ,
                    "Uzavřené " + getTabName()
                 ]}>
                    <Box sx={{'& > *': { mb: 2 }}}>     
                    <PrintableWrapper>
                        <UpcomingProcessesTable data={filteredData.filter((item) => new Date(item.startDate) > today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))} />
                        <ProcessesSummary data={data.filter((item) => new Date(item.startDate) > today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))}/>
                    </PrintableWrapper>
                    </Box>
                    <Box sx={{'& > *': { mb: 2 }}}>     
                    <PrintableWrapper>                        
                        <UnclosedProcessesTable data={filteredData.filter((item) => new Date(item.startDate) <= today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))} />
                        <ProcessesSummary data={data.filter((item) => new Date(item.startDate) <= today && !item.finished && (categoryFilter === 0 || item.category === categoryFilter))}/>
                    </PrintableWrapper>
                    </Box>
                    <Box sx={{'& > *': { mb: 2 }}}>     
                    <PrintableWrapper>
                        <ClosedProcessesTable data={filteredData.filter((item) => item.finished && (categoryFilter === 0 || item.category === categoryFilter))} />
                        <ProcessesSummary data={data.filter((item) => item.finished && (categoryFilter === 0 || item.category === categoryFilter))}/>
                    </PrintableWrapper>
                    </Box>
                </TabLayout>
                {props.select !== undefined && 
                    <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{pt:1}}
                    >
                        <FormModal 
                            buttonText={"Vytvořit " + getModalName()}
                            title={"Vytvořit novou " + getModalName()}    
                            form={<ProcessForm buttonText="Vytvořit" model={null} finishing={false}
                                category={props.category === undefined ? CategoryType.Activity : props.category}
                                selectOptions={props.select}
                                onSubmit={ProcessService.createProcess} 
                                onSubmitRepeating={ProcessService.updateRepeatingProcess}/>}
                        />    
                    </Stack>
                }
            </Box>
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }