import PropertiesInfoTable from "../../../common/components/tables/PropertiesInfoTable";

export interface ISummaryProps{
    data: any[];
}

export default function BuildingsSummary(props: ISummaryProps){
    const data = {
        count: props.data.length,
        areaSum: props.data.reduce((a, b) => a + b.enclosedArea, 0).toFixed(2),
        floorsAreaSum: props.data.reduce((a, b) => a + b.floorsArea, 0).toFixed(2),
        roomsAreaSum: props.data.reduce((a, b) => a + b.roomsArea, 0).toFixed(2),
        itemsCount: 0,
    }
    return <PropertiesInfoTable
        data={data}
        rowDefinition={[{
            name : "Součet ploch budov", 
            property : "areaSum",            
        },{
            name : "Součet ploch pater", 
            property : "floorsAreaSum",            
        },{
            name : "Součet ploch prostorů", 
            property : "roomsAreaSum",            
        },{
            name : "Počet prvků", 
            property : "itemsCount",            
        },
    ]}           
    />
}