import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SideWindowType } from "../layouts/OverviewSplitManager";
import { SideWindowContext } from "../contexts/SideWindowContext";
import EstateService from "../services/EstateService";
import FloorsHierarchy from "../components/content/FloorsHierarchy";
import RoomsHierarchy from "../components/content/RoomsHierarchy";
import FilesHierarchy from "../components/content/FilesHierarchy";
import ProcessesHierarchy from "../components/content/ProcessesHierarchy";
import SensorsHierarchy from "../components/content/SensorsHierarchy";
import BuildingInfo from "../components/tables/BuildingInfo";
import { Feature } from "ol";
import { IVectorLayer, IVectorData } from "../components/maps/ControlMap";
import { getBuildingStyle } from "../components/maps/ControlMapStyles";
import { Point } from "ol/geom";
import { CategoryType, SkobaEntityType } from "../../common/types/skobaTypes";
import { IBuildingDetailed } from "../types/estateTypes";
import HierarchySinglePageNew from "../../common/pages/HierarchySinglePageNew";
import LoadingWrapper from "../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../common/contexts/RefreshDataContext";
import ItemsHierarchy from "../../items/components/content/ItemsHierarchy";
import ItemService from "../../items/services/ItemService";
import FileService from "../../files/services/FileService";
import PrintableWrapper from "../../common/components/PrintableWrapper";
import { prepareWFSLink } from "../components/maps/MapFunctions";


export default function BuildingPage(){    
    const { changeSideWindow, openSideWindow, openHierarchy, setSwitcherOptions, localMap, matterport} = useContext(SideWindowContext);
    const navigate = useNavigate();
    
    const [data, setData] = useState<IBuildingDetailed>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();

    useEffect(() => {
        loadData();
        openHierarchy();
    }, [])    
    useEffect(() => {
        if(data !== undefined){
            setSwitcherOptions((data.hasDrawing || data.hasMDTile), data.hasMatterport)
            if(data.hasDrawing || data.hasMDTile){
                openSideWindow();
                changeSideWindow(SideWindowType.LocalMap);
            }
            else if(data.hasMatterport){
                openSideWindow();
                changeSideWindow(SideWindowType.Matterport);
            }
        }
        
    }, [data])
    useEffect(() => {
        if(data !== undefined){
            setMapVectorData();
        }
        
    }, [data, localMap])
    useEffect(() => {
        if(data !== undefined){
            setMatterportData();
        }
        
    }, [data, matterport])
    function loadData(){
        EstateService.getHierarchyData("Buildings/" + id).then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(data && localMap){          
            localMap?.setActiveConnectors([{id: data.id, type: SkobaEntityType.Building}])
            localMap?.setWfsLayers(prepareWFSLink(data.id, SkobaEntityType.Building));
        }       
    }
    function setMatterportData(): void{
        if(data && matterport){          
            matterport?.loadData(data.id, SkobaEntityType.Building)
        }       
    }
    //TODO: replace all static urls
    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            { data &&
                <HierarchySinglePageNew
                title={data!.name}                
                id={data.id} 
                type={SkobaEntityType.Building} 
                tabTitles={["Informace", "Patra", "Prostory", "Čidla", "Prvky", "Revize", "Činnosti", "Soubory"]}>                    
                    <PrintableWrapper>
                        <BuildingInfo data={data!} onMount={() => {
                                if(data?.hasDrawing){
                                    openSideWindow();
                                    changeSideWindow(SideWindowType.LocalMap);
                                    setMapVectorData();
                                }
                            }}
                            onRefresh={() => {}}
                            refreshProps={[data, localMap]}
                        />
                    </PrintableWrapper>                    
                    <FloorsHierarchy 
                        showLocalMap={data.hasDrawing || data.hasMDTile}
                        showMatterport={data.hasMatterport}
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Building, data.id, SkobaEntityType.Floor)}/>
                    <RoomsHierarchy
                        parent={{id: data.id, name: data.name}}
                        showLocalMap={data.hasDrawing || data.hasMDTile}
                        showMatterport={data.hasMatterport}
                        buildingOptions={{id: data.id, name: data.name}}
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Building, data.id, SkobaEntityType.Room)}/>
                    <SensorsHierarchy 
                        parent={{name: data.name, id: data.id}} 
                        getData={async () => EstateService.getAllOfParent(SkobaEntityType.Building, data.id, SkobaEntityType.Sensor)}/>
                    <ItemsHierarchy
                        location={{name: data.name, id: data.id, type: SkobaEntityType.Building}}
                        getData={async (category: CategoryType, subCategory: CategoryType) => ItemService.getAllOfParent(SkobaEntityType.Building, data.id, category, subCategory)}/>
                    <ProcessesHierarchy 
                        category={CategoryType.Revision}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Building}}/>
                    <ProcessesHierarchy 
                        category={CategoryType.Activity}
                        select={{name: data.name, id: data.id, type: SkobaEntityType.Building}}/>
                    <FilesHierarchy getData={() => FileService.getAllOfParent(SkobaEntityType.Building, data.id)}
                        parent={{name: data.name, id: data.id, type: 1}}  />
                </HierarchySinglePageNew>     
            }
        </LoadingWrapper>        
    </RefreshDataContext.Provider>    
  }