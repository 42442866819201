import { createEmpty, extend } from "ol/extent";
import { Geometry } from "ol/geom";
import { Layer } from "ol/layer";
import { fromLonLat, Projection } from "ol/proj";
import VectorSource from "ol/source/Vector";
import React, { ReactNode } from "react";
import { RLayerTile, RLayerTileWMS, RLayerVector, RMap } from "rlayers";
import { RView } from "rlayers/RMap";
import "./Map.css"
import "ol/ol.css";
import { IVectorLayer } from "./ControlMap";
import {createCustomWFSLoader, GeometryType, getMDFileSource, onFeaturesLoadEnd} from "./MapFunctions";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper } from "@mui/material";
import { GeoJSON } from "ol/format";
import { IConnectorEntityDetailed, SkobaEntityType } from "../../../common/types/skobaTypes";
import { getPolygonNewStyle, getTextPointStyle } from "./LocalMapStyles";
import { RStyle } from "rlayers/style";
import { Feature } from "ol";
import { Navigate } from "react-router-dom";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { Source, TileWMS } from "ol/source";
import TileLayer from "ol/layer/Tile";

export interface IActiveConnector{
    type: SkobaEntityType;
    id: number;
}

export interface ILocalMapState{
    view : RView;
    map : React.RefObject<RMap>;
    wfsLayers : IWFSLayer[];
    vectorLayers : IVectorLayer[];
    // projection : Projection;
    loaded: boolean;
    cursorPosition: number[];
    activeConnectors: IActiveConnector[];
    sideMenuMode: number;
    navigateTo: string;
    checkboxesState: {
        [key: string]: boolean;
    };

}
export interface IWFSLayer{
    style: (active: boolean, text?: string) => JSX.Element;
    link: string;
    type: GeometryType;
    onEnter: () => void;
    onLeave: () => void;
    onClick: (id: number) => void;
}


const origin = [14.4620, 50.0824];

// const newCenter = [-544006.67 -1144002.81]
proj4.defs('EPSG:404000', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
register(proj4);


const osmProj = new Projection({
    code: 'EPSG:5514',
    units: 'degrees',
    global: true
});

const espgProjection = new Projection({
    code: 'EPSG:4326',
    units: 'degrees',
    global: true
});

export interface ILocalMapProps{
}

class LocalMap extends React.Component<ILocalMapProps, ILocalMapState>{
    loadedLayers = 0;
    constructor(props : ILocalMapProps){
        super(props);
        this.state = ({
            navigateTo: "",
            view: { center: fromLonLat(origin), zoom: 8 },
            map : React.createRef() as React.RefObject<RMap>,
            wfsLayers: [],
            // projection : osmProj,
            cursorPosition : [0,0],
            loaded : false,
            vectorLayers : [],
            activeConnectors: [],
            checkboxesState: {
                checkbox1: true,
                checkbox2: true,
                checkbox3: true
            },
            sideMenuMode: -1,

        })        
    }
    refreshMap() : void{
        this.state.map?.current?.ol.updateSize();
    }
    //think about storing previous center and zoom
    zoomToFeature(keyPair: string[], level = 23){
        this.state.map.current?.ol.getAllLayers().forEach(x => {
            const feature = (x as Layer<VectorSource<Feature<Geometry>>>).getSource()?.getFeatures().find(x => x.get(keyPair[0]) === keyPair[1])
            if(feature){
                const extent = feature.getGeometry()?.getExtent()
                if(extent && extent[0] !== Infinity){
                    this.state.map.current?.ol.getView().fit(extent);
                    this.state.map.current?.ol.getView().setZoom(level);
                }  
            }
        })                   
    }
    centerOnLayers() : void{
        const layers = this.state.map.current?.ol.getAllLayers() as Layer<VectorSource<Feature<Geometry>>>[];
        // const layers = this.state.map.current?.ol.getAllLayers().filter(x => !(x instanceof TileLayer)) as Layer<VectorSource<Feature<Geometry>>>[];
        if(layers && layers.length !== 0){
            const extent = createEmpty();
            layers.forEach(x => {
                const tempExtent =  x.getSource()?.getExtent();
                if(tempExtent)
                    extend(extent, tempExtent);
            })
            if(extent[0] !== Infinity){
                this.state.map.current?.ol.getView().fit(extent, {size: this.state.map.current?.ol.getSize()});
            }
        }       
    }
    handleCheckboxClick(event: React.ChangeEvent<HTMLInputElement>){
        const { name, checked } = event.target;
        this.setState({
            checkboxesState: {
                ...this.state.checkboxesState,
                [name]: checked,

            }
        })
    };
    setWfsLayers(data : IWFSLayer[]){
        this.loadedLayers = 0;
        this.setState({
            navigateTo: "",
            wfsLayers : data,    
            loaded : false,        
        })
    }
    setActiveConnectors(connectors: IActiveConnector[]){
        this.setState({
            activeConnectors: connectors
        })
    }
    getLayers(): ReactNode{
        return <div></div>
    }
    getWMSLayers(): ReactNode{        
        const check3 = this.state.checkboxesState["checkbox3"]
        return <React.Fragment>
            {check3 &&  <RLayerTile
        properties={{source: getMDFileSource(this.state.activeConnectors) }}
        />}
        </React.Fragment>
    }
    handleSideMenuClick(mode: number){
        if(mode === this.state.sideMenuMode){
            this.setState({
                sideMenuMode: -1
            })
        }
        else{
            this.setState({
                sideMenuMode: mode
            })
        }
    }
    getWfsLayers(): ReactNode{        
        //TODO: LEARN TO USE CALLBACKS https://mmomtchev.github.io/rlayers/#/geodata
        return <React.Fragment>
            {this.state.wfsLayers.map((x, idx) =>{
                return <RLayerVector key={idx}
                    zIndex={15}                
                    format={new GeoJSON({ featureProjection: "EPSG:3857"})}
                    onFeaturesLoadEnd={onFeaturesLoadEnd}
                    loader={createCustomWFSLoader(x.link, () => this.centerOnLayers())}                    
                    onClick={(e) => {
                        if(x.link.includes("point") || x.link.includes("polygonnew/")){
                            const connector = e.target.get("connector") as IConnectorEntityDetailed
                            this.setState({
                                navigateTo: "/estate/rooms/" + connector.parentId
                            })
                        }
                    }}
                    onPointerEnter={(e) => {
                        if(x.link.includes("polygons"))
                            $("#row-" + e.target.get("cislo_mist").replace(/ /g, '').replace(".","_")).addClass("selected-table-row");
                    }}
                    onPointerLeave={(e) => {
                        if(x.link.includes("polygons"))
                            $("#row-" + e.target.get("cislo_mist").replace(/ /g, '').replace(".","_")).removeClass("selected-table-row");
                    }}                
                >
                    {x.link.includes("polygonnew/") && 
                        <RStyle
                            render={(f) => {
                                const connector = f.get("connector") as IConnectorEntityDetailed;
                                const check1 = this.state.checkboxesState["checkbox1"]
                                const check2 = this.state.checkboxesState["checkbox2"]
                                const isActive = this.state.activeConnectors.find(c => c.id == connector.parentId) !== undefined; 
                                return getPolygonNewStyle(isActive, check1, check2, connector.parentName)
                            }}
                        />
                    }
                    {(x.link.includes("point/") || x.link.includes("polygon/")) ?
                        <RStyle
                            render={(f) => {
                                const connector = f.get("connector") as IConnectorEntityDetailed;
                                console.log(connector)
                                const isActive = this.state.activeConnectors.find(c => c.id == connector.parentId) !== undefined;                                
                                if(x.link.includes("point"))
                                    return getTextPointStyle(isActive, connector.parentName)
                                return x.style(isActive)
                            }}
                        />
                    :                        
                        <RStyle>
                            {x.style(false)}
                        </RStyle>
                    }
              </RLayerVector>
            })}
        </React.Fragment>    
    }
    render(): React.ReactNode {
        this.refreshMap();
        return (
        <React.Fragment>
            <RMap
                ref={this.state.map}
                className="local-map"
                // initial={{center: fromLonLat(origin), zoom: 8}}
                initial={{center: [0.0,0.0], zoom: 22}}
                // view={[this.state.view, (view) => this.setState({view: view})]}
                onChange={() => {console.log("map changed")}}                
                onClick={(e) => {
                    this.setState({
                        cursorPosition: e.map.getCoordinateFromPixel(e.pixel)
                    })
                }}
                projection={"EPSG:3857"}
                >
                {this.getWMSLayers()}
                {this.getWfsLayers()}
            </RMap>
            <Box sx={{
                    p: 0,
                    position: "absolute",
                    top: "0",
                    right: "0",
                    transform: "translate(0, 0)",
                    zIndex: 100,
                    backgroundColor: "white",
                    }}>                        
                        <Paper elevation={3} sx={{
                                paddingTop: 1,
                                paddingBottom: 1,
                                paddingLeft: 1,
                                display: 'flex',
                                flexDirection: 'row',  
                                gap: "5px",
                                backgroundColor: "#f2f2f2"
                            }}>
                            <Button size="small" 
                                variant="outlined"
                                onClick={() => 
                                    this.centerOnLayers()}
                            >
                                CENTER
                            </Button>
                            <Button 
                                onClick={() => this.handleSideMenuClick(2)}
                                variant={this.state.sideMenuMode == 2 ? "contained" : "outlined"}
                                size="small">
                                vrstvy
                            </Button>
                        </Paper>
                        {this.state.sideMenuMode == 2 &&
                            <Paper elevation={3} sx={{
                                paddingTop: 1,
                                paddingBottom: 1,
                                paddingX: 1,
                                marginTop: 1,
                                display: 'flex',
                                flexDirection: 'column',  
                                gap: "5px",
                                backgroundColor: "#f2f2f2",
                                width: "fit-content",
                                alignSelf: "end"

                            }}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox name="checkbox3" size="small" onChange={(e) => this.handleCheckboxClick(e)} checked={this.state.checkboxesState["checkbox3"]}/>} label="Podklad" />
                                    <FormControlLabel control={<Checkbox name="checkbox1" size="small" onChange={(e) => this.handleCheckboxClick(e)} checked={this.state.checkboxesState["checkbox1"]}/>} label="Polygony" />
                                    <FormControlLabel control={<Checkbox name="checkbox2" size="small" onChange={(e) => this.handleCheckboxClick(e)} checked={this.state.checkboxesState["checkbox2"]}/>} label="Číslo míst." />
                                </FormGroup>
                            </Paper>
                        } 
                </Box>
            <div className="map-info">
                <div className="map-info-text">
                    Pozice posledního kliknutí: <strong>{this.state.cursorPosition[0].toFixed(3)} {this.state.cursorPosition[1].toFixed(3)}</strong>
                </div>
                
            {this.state.navigateTo !== "" &&
                <React.Fragment>
                    <Navigate to={this.state.navigateTo} replace={true}/>
                </React.Fragment>
            }
            </div>
        </React.Fragment>
        )
    }
}
export default LocalMap