import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstateService from "../../services/EstateService";
import { SideWindowType } from "../../layouts/OverviewSplitManager";
import { SideWindowContext } from "../../contexts/SideWindowContext";
import { SkobaEntityType, ISelect, ISelectHierarchical } from "../../../common/types/skobaTypes";
import { IRoomDetailed } from "../../types/estateTypes";
import RoomsTable from "../tables/RoomsTable";
import TwoTextFilter from "../../../common/components/filters/DualTextFilter";
import RoomForm from "../forms/RoomForm";
import HierarchyFiltersMultipleNew from "../../../common/components/hierarchy/HierarchyFiltersMultipleNew";
import LoadingWrapper from "../../../common/components/LoadingWrapper";
import { RefreshDataContext } from "../../../common/contexts/RefreshDataContext";
import FloorsSummary from "../tables/FloorsSummary";
import { prepareWFSLink } from "../maps/MapFunctions";
import RoomsSummary from "../tables/RoomsSummary";
import FormModal from "../../../categories/components/modals/FormModal";
import ReactToPrint from "react-to-print";
import PrintableWrapper from "../../../common/components/PrintableWrapper";


export interface IRoomsHierarchyProps{
    premiseOption?: ISelect;
    buildingOptions?: ISelect;
    floorOptions?: ISelect; 
    parent?: ISelect;
    showLocalMap?: boolean;
    showMatterport?: boolean
    getData : () => Promise<any[]>;
}

export default function RoomsHierarchy(props: IRoomsHierarchyProps){
    const { changeSideWindow, openSideWindow, closeSideWindow, openHierarchy, localMap, matterport, setSwitcherOptions } = useContext(SideWindowContext);
    const navigate = useNavigate();
    const componentRef = useRef<HTMLDivElement>(null);

    const [data, setData] = useState<IRoomDetailed[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadData();
        openHierarchy();
    }, [])
    useEffect(() => {
        if(data !== undefined){
            setSwitcherOptions(props.showLocalMap === undefined ? false : props.showLocalMap, props.showMatterport === undefined ? false : props.showMatterport)
            if(props.showLocalMap){
                openSideWindow();
                changeSideWindow(SideWindowType.LocalMap);
            }
            else if(props.showMatterport){
                openSideWindow();
                changeSideWindow(SideWindowType.Matterport);
            }
            else
                closeSideWindow();
        }
        if(props.showLocalMap === undefined && props.showMatterport === undefined){
            closeSideWindow();
        }
        
    }, [data])
    useEffect(() => {
        if(data !== undefined){
            setMapVectorData();
        }
        
    }, [data, localMap])
    useEffect(() => {
        if(data !== undefined){
            setMatterportData();
        }
        
    }, [data, matterport])
    function loadData(){
        props.getData().then(
            (result) => {
                setLoading(false);
                setData(result);
            },
            (error) => {
                setError(true);
                setErrorMessage(error);
            }
        )
    }
    function setMapVectorData(): void{
        if(props.buildingOptions && localMap){            
            localMap?.setWfsLayers(prepareWFSLink(props.buildingOptions?.id, SkobaEntityType.Building));            
        }
    }
    function setMatterportData(): void{
        if(data && matterport && props.parent){          
            matterport?.loadData(props.parent?.id, SkobaEntityType.Building)
        }       
    }

    return <RefreshDataContext.Provider value={loadData}>
        <LoadingWrapper error={error} errorObject={errorMessage} loading={loading}>
            <PrintableWrapper>
                <HierarchyFiltersMultipleNew
                    data={data}
                    getChildrenTable={(data) => <RoomsTable data={data} parentBuilding={props.buildingOptions}/>}
                    getFilters={(data, onChange) => 
                        <TwoTextFilter 
                            data={data} 
                            onChange={onChange} 
                            labelTextFirst="Hledat dle názvu"
                            labelTextSecond="Hledat dle čísla"
                            filterPropertyFirst="name"
                            filterPropertySecond="number"
                        /> 
                    }
                    modifyButtons={(props.premiseOption || props.buildingOptions || props.floorOptions) ? [
                        <FormModal 
                            buttonText="Vytvořit prostor"
                            title="Vytvořit novou prostor"    
                            form={<RoomForm model={null}                         
                                selectable={false}
                                buttonText="Vytvořit"
                                onSubmit={EstateService.createRoom}
                                premiseOption={props.premiseOption}
                                buildingOptions={props.buildingOptions}
                                floorOptions={props.floorOptions}
                            />}
                        /> 
                    ] : []}
                    />
                <RoomsSummary data={data}/>
            </PrintableWrapper>            
        </LoadingWrapper>        
    </RefreshDataContext.Provider>
  }